const { filter } = require('lodash')

try {
    window.$ = window.jQuery = require('jquery')
    window.toastr = require('toastr')
    toastr.options = {
        closeButton: false,
        debug: false,
        newestOnTop: false,
        progressBar: false,
        positionClass: 'toast-top-right',
        preventDuplicates: false,
        onclick: null,
        showDuration: '300',
        hideDuration: '1000',
        timeOut: '5000',
        extendedTimeOut: '1000',
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
    }
} catch (e) {}

;(function() {
    var user_id = 0
    $('input').attr('autocomplete', 'off')

    if (location.hash) {
        const name = location.hash.replace('#', '')
        $('.settings .tabs .item').removeClass('active')
        $('.settings .contentTabs .item').removeClass('active')

        $(".settings .tabs [data-name='" + name + "']")
            .fadeIn()
            .addClass('active')
        $('.settings .contentTabs .item.' + name)
            .fadeIn()
            .addClass('active')
    }

    $('select').each(function(k, v) {
        let val = $(v).attr('value')
        if (val) {
            $(v)
                .find('option[value=' + val + ']')
                .attr('selected', 'selected')
        }
    })

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
        },
    })
    // $('.contentTabs').mousedown(function(){ return false; })
    $('.dropdown').click(function() {
        if ($('.dropdown').hasClass('active') && !$(this).hasClass('active')) {
            $('.dropdown').removeClass('active')
        }
        $(this).toggleClass('active')
    })

    $('.listIcon').click(function() {
        $('.products .articules').css('opacity', '0')
        $('.products .articules').removeClass('active')
        setTimeout(function() {
            $('.products .articules').css('opacity', '1')
        }, 100)
    })
    $('.menuIcon').click(function() {
        $('.products .articules').css('opacity', '0')
        $('.products .articules').addClass('active')
        setTimeout(function() {
            $('.products .articules').css('opacity', '1')
        }, 100)
    })
    $('.product .contentImages .image').click(function(e) {
        var img = $(this).data('image')
        $(this)
            .addClass('active')
            .siblings('.image')
            .removeClass('active')
        $(this)
            .parents('.contentImages')
            .find('.default')
            .css('background-image', "url('" + img + "')")
    })
    $('.contentCart .more').click(function() {
        var val = parseInt(
            $(this)
                .siblings('input')
                .first()
                .val()
        )
        $(this)
            .siblings('input')
            .first()
            .val(val + 1)
    })
    $('.contentCart .less').click(function() {
        var val =
            parseInt(
                $(this)
                    .siblings('input')
                    .first()
                    .val()
            ) - 1
        $(this)
            .siblings('input')
            .first()
            .val(val <= 0 ? 1 : val)
    })
    $('.product .contentImages .default').mousemove(function() {
        if ($(window).width() > 480) {
            var sizeImage = {
                width: $(this).width(),
                height: $(this).height(),
            }
            var magnifying = {
                moveX: event.pageX,
                moveY: event.pageY,
            }
            var origin = {
                x: $(this).offset().left + sizeImage.width / 2,
                y: $(this).offset().top + sizeImage.height / 2,
            }
            var effect = {
                left: (origin.x - magnifying.moveX) / 1.5,
                down: (origin.y - magnifying.moveY) / 1.5,
            }

            $(this)
                .find('.imageZoom .zoom')
                .css('transform', 'scale(3) translateX(' + effect.left + 'px) translateY(' + effect.down + 'px)')
            $(this)
                .find('.magnifying')
                .css(
                    'transform',
                    'translateX(' +
                        (magnifying.moveX - 75 - $(this).offset().left) +
                        'px) translateY(' +
                        (magnifying.moveY - 50 - $(this).offset().top) +
                        'px)'
                )
        }
    })

    $('.product .image').click(function() {
        var link = $(this).data('image')
        $('.zoom').css('background-image', 'url(' + link + ')')
    })

    var interval
    $(document).on('keyup keypress', '.header .search input', function(event) {
        var q = $(this).val()

        let keycode = event.keyCode ? event.keyCode : event.which
        if (keycode == '13') {
            window.location = '/products?search=' + q
        }

        clearInterval(interval)
        interval = setInterval(function() {
            $.ajax({
                data: { q },
                type: 'GET',
                dataType: 'json',
                url: '/api/search',
            })
                .done(function(r) {
                    if (r.success) {
                        drawResults(r.data)
                    }
                })
                .fail(function(e) {
                    console.error(e)
                })
            clearInterval(interval)
        }, 1000)
    })

    function drawResults(r) {
        var html = ''
        $('.header .search').addClass('active')
        if (r.length > 0) {
            $.each(r, function(e, v) {
                html += `<a class="opcion" href="/product/${v.slug}" title="${v.name}">
        <div class="image" style="background-image:url('${v.default_image}')"></div>
        <div class="info">
        <div class="name">${v.name}</div>
        <div class="description">
        <div class="sku">SKU: <span>${v.sku}</span></div>
        <div class="codebars">CODIGO: <span>${v.barcode}</span></div>
        </div>
        </div>
        </a>`
            })
            $('.header .search .result')
                .html(html)
                .css('height', '325px')
        } else {
            html += "<div class='error'>No hay resultados para tu busqueda.</div>"
            $('.header .search .result')
                .html(html)
                .css('height', '50px')
        }
    }

    $(document).on('click', '.header .search.active .button, .header .search.active .error', function() {
        $(this)
            .parents('.search')
            .removeClass('active')
        $(this)
            .parents('.search')
            .find('input')
            .val('')
    })

    $(document).on('click', '.product .addCart', function() {
        $form = $(this).parents('.contentCart')
        $form.attr('action', '/addCart')
        $form.attr('method', 'post')
        $form.submit()
    })

    $(document).on('click', '.cart table .deleteIcon', function(e) {
        let id = $(this).data('id')
        let $form = $('#formCart')
        let $id = $('<input/>', { value: id, name: 'id', type: 'hidden' })
        $form.append($id)
        $form.attr('action', '/deleteCart')
        $form.attr('method', 'post')
        $form.submit()
    })

    $(document).on('click', '.cart .quantity .less, .cart .quantity .more', function(e) {
        //product_id
        let product_id = $(this)
            .siblings('input')
            .data('id')
        let $product_id = $('<input/>', { value: product_id, name: 'product_id', type: 'hidden' })
        //quantity
        quantity = $(this).hasClass('less') ? -1 : 1
        let $quantity = $('<input/>', { value: quantity, name: 'quantity', type: 'hidden' })

        //form
        let $form = $('#formCart')
        $form.append($quantity)
        $form.append($product_id)
        $form.attr('action', '/addCart')
        $form.attr('method', 'post')
        $form.submit()
    })

    $(document).on('keypress paste focusout ', '.product .field .quantity', function(e) {
        var val = $(e.currentTarget).val()
        if (val < 0 || !$.isNumeric(val) || isNaN(val)) {
            val = 0
        }
        $(e.currentTarget).val(parseInt(val))
        var key = window.Event ? e.which : e.keyCode
        if ($(e.currentTarget).val().length > 11) {
            return false
        }
        return key >= 48 && key <= 57
    })

    $(document).on('click', '.login .tabs .name', function() {
        $(this)
            .addClass('active')
            .siblings('.name')
            .removeClass('active')
        var name = $(this).data('name')
        if (name == 'login') {
            $('.login .contentTabs').animate({ scrollLeft: 0 }, 500)
        } else if (name == 'signup') {
            $('.login .contentTabs').animate({ scrollLeft: 600 }, 500)
        }
    })

    $(document).on('click', '.login .field svg', function() {
        $(this)
            .parent()
            .children('span')
            .toggle()
    })

    function validate(element, rule, value = '') {
        const regexpRFC = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/
        var result = false
        switch (rule) {
            case 'empty':
                result = $(element).val().length > 0
                break
            case 'email':
                result = $(element)
                    .val()
                    .includes('@')
                break
            case 'rfc':
                result = regexpRFC.test($(element).val())
                break
            case 'match':
                result = $(element).val() == $(value).val() && $(element).val().length > 0
                break
        }
        if (result) {
            $(element)
                .parents('.field')
                .removeClass('error')
        } else {
            $(element)
                .parents('.field')
                .addClass('error')
        }
        return result
    }

    $('#formLogin').submit(function(e) {
        e.preventDefault()
        var val = [validate('#email', 'email'), validate('#password', 'empty')]
        if (val.every(x => x == true)) {
            event.currentTarget.submit()
        }
    })

    $('#formSignup').submit(function(e) {
        e.preventDefault()
        var val = [
            validate('#NewName', 'empty'),
            // validate('#newLastName', 'empty'),
            // validate('#newSocialReason', 'empty'),
            validate('#newEmail', 'email'),
            validate('#NewPassword', 'empty'),
            validate('#newRepassword', 'empty'),
            validate('#newRepassword', 'match', '#NewPassword'),
        ]
        console.log("---- send", e)
        if (val.every(x => x == true)) {
            event.currentTarget.submit()
        }
    })

    function getFormData(array) {
        let obj = {}
        $.map(array, function(n, i) {
            obj[n['name']] = n['value']
        })
        return obj
    }

    $('#formProfile,#formDataBilling').submit(function(e) {
        e.preventDefault()
        let formId = $(e.currentTarget).attr('id')
        let formProfile = getFormData($('#formProfile').serializeArray())
        let formDataBilling = getFormData($('#formDataBilling').serializeArray())
        let data = {}

        if (formId == 'formProfile') {
            var val = [
                validate("input[name='name']", 'empty'),
                validate("input[name='last_name']", 'empty'),
                validate("input[name='email']", 'email'),
            ]
            data = {
                ...formProfile,
                customer_type_id: !formDataBilling.customer_type_id ? 1 : formDataBilling.customer_type_id,
            }
        } else if (formId == 'formDataBilling') {
            var val = [
                validate("input[name='rfc']", 'rfc'),
                validate("input[name='billing_address']", 'empty'),
                validate("select[name='customer_type_id']", 'empty'),
                validate("input[name='billing_zipcode']", 'empty'),
                validate("input[name='billing_email']", 'empty'),
                validate("input[name='business_name']", 'empty'),
            ]
            data = {
                ...formDataBilling,
                name: formProfile.name,
                last_name: formProfile.last_name,
                email: formProfile.email,
            }
        }

        if (val.every(x => x == true)) {
            let id = $(this)
                .find("input[name='id']")
                .val()
            $.ajax({
                data: data,
                type: 'PATCH',
                dataType: 'json',
                url: `/api/customers/${id}`,
                beforeSend: () => {
                    $('.save').addClass('disabled')
                },
            })
                .done(function(r) {
                    toastr.success('Los cambios se han guardado exitosamente.')
                })
                .fail(function(e) {
                    console.error(e)
                    if (e.responseJSON.hasOwnProperty('message')) {
                        toastr.error(e.responseJSON.message)
                    }
                })
                .always(() => {
                    $('.save').removeClass('disabled')
                })
        }
    })

    $('.settings .tabs .item').click(function() {
        let name = $(this).data('name')
        $(this)
            .addClass('active')
            .siblings('.item')
            .removeClass('active')
        $('.settings .contentTabs .item')
            .hide()
            .removeClass('active')
        $('.settings .contentTabs .item.' + name)
            .fadeIn()
            .addClass('active')
    })

    $(document).on('keyup paste ', "input[name='billing_zipcode']", function(e) {
        let zipcode = $(this).val()
        let $this = $(this)
        if (zipcode.length >= 5) {
            clearInterval(interval)
            interval = setInterval(function() {
                $.ajax({
                    type: 'GET',
                    dataType: 'json',
                    url: '/api/address/' + zipcode,
                    beforeSend: function() {
                        $this.parent().addClass('loading')
                    },
                })
                    .done(function(r) {
                        $("input[name='state']").val(r.state)
                        $("input[name='city']").val(r.city)
                        $("select[name='billing_suburb_id']").empty()
                        $.each(r.suburbs, function(k, v) {
                            $("select[name='billing_suburb_id']").append(
                                "<option value='" + v.id + "'>" + v.name + '</option>'
                            )
                        })
                    })
                    .fail(function(e) {
                        console.error(e)
                    })
                    .always(function() {
                        $this.parent().removeClass('loading')
                    })
                clearInterval(interval)
            }, 1000)
        }
    })

    function priceFormat(number) {
        if ($.isNumeric(number)) {
            var formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2,
            })
            number = formatter.format(number)
        } else {
            number = '$0.00'
        }
        return number
    }

    function setDataModal(response) {
        $('#modalOrders .modalContent .header span').html('Resumen de la orden')
        $('#modalOrders .content tbody').empty()
        $.each(response.items, function(k, v) {
            let tbody = `<tr>
              <td>${v.name}</td>
              <td>${v.quantity}</td>
              <td>${priceFormat(v.priceWithTax)} MXN</td>
              <td>${priceFormat(v.total)} MXN</td>
            </tr>`
            $('#modalOrders .content tbody').append(tbody)
        })
        let tfoot = `<p><b >Subtotal: </b><span>${priceFormat(response.sub_total)} MXN</span>  </p>
              <p><b >Impuestos: </b> <span>${priceFormat(response.tax_amount)} MXN</span> </p>
              <p><b >Descuentos: </b> <span>${priceFormat(response.discount_amount)} MXN</span></p>
              <p><b >Total: </b><span>${priceFormat(response.grand_total)} MXN</span> </p>`

        $('#modalOrders .content .tfoot').html(tfoot)
        $('#modalOrders .content .box-data .code span').html(response.unique_id)
        $('#modalOrders .content .box-data .date span').html(response.created_at)
    }

    $(document).on('click', '.orders .option.products', function() {
        var unique_id = $(this).data('unique_id')
        $.ajax({
            type: 'GET',
            dataType: 'json',
            url: '/getOrder/' + unique_id,
        })
            .done(function(response) {
                setDataModal(response)
                $('#modalOrders')
                    .fadeIn(function(e) {
                        $(this).addClass('active')
                    })
                    .css('display', 'flex')
                $('body').addClass('withoutScroll')
            })
            .fail(function(e) {
                console.error(e)
            })
    })

    $(document).on('click', '.modalContent .header i,.modalContent .actions .button', function(e) {
        $(this)
            .parents('.modal')
            .fadeOut(function(e) {
                $(this).removeClass('active')
                $('body').removeClass('withoutScroll')
            })
    })

    $(document).on('click', '.logout', function(e) {
        event.preventDefault()
        $('#form')
            .attr('action', '/logout')
            .submit()
    })

    $(document).on('keyup paste', '#code', function(e) {
        let unique_id = $(this).val()
        let $this = $(this)
        let customer_id = $('#formBilling input[name="customer_id"]')
            .first()
            .val()

        $('#code')
            .parents('.field')
            .find('.error')
            .hide()
        $('#box-billing').fadeOut()
        clearInterval(interval)
        interval = setInterval(function() {
            if (unique_id.length) {
                $.ajax({
                    type: 'GET',
                    dataType: 'json',
                    url: '/getOrder/' + unique_id,
                    beforeSend: function() {
                        $this.parent().addClass('loading')
                    },
                })
                    .done(function(response) {
                        if (response.customer_id != customer_id || response.customer_id != 1) {
                            //solo permite publico en general
                            $('#code')
                                .parents('.field')
                                .find('.error')
                                .html('La orden le pertenece a otro cliente.')
                                .fadeIn()
                            $('#box-billing').fadeOut()
                        } else if (Object.keys(response).length === 0) {
                            $('#code')
                                .parents('.field')
                                .find('.error')
                                .html('No se encontró la orden.')
                                .fadeIn()
                            $('#box-billing').fadeOut()
                        } else if (response.invoice_uuid != null) {
                            $('#code')
                                .parents('.field')
                                .find('.error')
                                .html('La orden ya se encuentra facturada.')
                                .fadeIn()
                            $('#box-billing').fadeOut()
                        } else if (response.order_status_id == 1) {
                            $('#code')
                                .parents('.field')
                                .find('.error')
                                .html('La orden se encuentra en proceso.')
                                .fadeIn()
                            $('#box-billing').fadeOut()
                        } else if (response.order_status_id == 2) {
                            $('#code')
                                .parents('.field')
                                .find('.error')
                                .html('La orden se encuentra cancelada.')
                                .fadeIn()
                            $('#box-billing').fadeOut()
                        } else {
                            $('#box-billing').fadeIn()
                            setDataModal(response)
                        }
                    })
                    .fail(function(e) {
                        console.error(e)
                    })
                    .always(function() {
                        $this.parent().removeClass('loading')
                    })
            }
            clearInterval(interval)
        }, 1000)
    })

    $('#btnShowProducts').click(function() {
        $('#modalOrders')
            .fadeIn(function(e) {
                $(this).addClass('active')
            })
            .css('display', 'flex')
    })

    $('#btnShowStock').click(function() {
        $('#modalStock')
            .fadeIn(function(e) {
                $(this).addClass('active')
            })
            .css('display', 'flex')
    })

    $('#formBilling').submit(function(event) {
        event.preventDefault()

        let formArray = $(this).serializeArray()
        let data = new Object()
        $.each(formArray, function(i, item) {
            data[item.name] = item.value
        })
        data.currentCustomer = { id: data.customer_id }

        var val = [
            validate("select[name='CFDIUsage']", 'empty'),
            validate("select[name='paymentMethod']", 'empty'),
            validate("select[name='paymentWay']", 'empty'),
        ]

        if (val.every(x => x == true)) {
            $.ajax({
                data: data,
                type: 'POST',
                dataType: 'json',
                url: '/api/billing',
                beforeSend: function() {
                    $('#formBilling button.save').addClass('disabled')
                },
            }).done(function(response) {
                $('#modalBilling .actions .button').attr('href', '/orders/' + response.order_id + '/invoice')
                $('#modalBilling')
                    .fadeIn(function(e) {
                        $(this).addClass('active')
                    })
                    .css('display', 'flex')
                $('#formBilling button.save').removeClass('disabled')
                $('#code').val('')
                $('#box-billing').fadeOut()
            })
        }
    })

    $('.categoryList,.removeIcon').click(function(e) {
        $('.mainNavigator').toggleClass('active')
        if ($('.mainNavigators').hasClass('active')) {
            $('body').addClass('withoutScroll')
        } else {
            $('body').removeClass('withoutScroll')
        }
    })

    $(document).on('click', '.option.cancel', function() {
        let id = $(this).data('id')
        let $form = $('#form')
        let $id = $('<input/>', { value: id, name: 'id', type: 'hidden' })

        $form.append($id)
        $form.attr('action', '/api/orderCancel')
        $form.attr('method', 'post')
        $form.submit()
    })

    $(document).on('click', '.subsidiary', function() {
        let lat = $(this).data('lat')
        let lng = $(this).data('lng')
        let title = $(this)
            .find('.title')
            .html()
        let box = $(this).html()

        const uluru = { lat, lng }
        const map = new google.maps.Map(document.getElementById('map'), {
            zoom: 15,
            center: uluru,
        })
        const marker = new google.maps.Marker({
            position: uluru,
            map: map,
        })

        $('#modalMap')
            .find('.header .title')
            .html(title)
        $('#modalMap')
            .find('.box')
            .html(box)
        $('#modalMap')
            .find('.box .title')
            .remove()
        $('#modalMap')
            .fadeIn(function(e) {
                $(this).addClass('active')
            })
            .css('display', 'flex')
        let mail = $('#modalMap')
            .find('.box .email')
            .html()
        $('#modalMap')
            .find('.box .email')
            .html("<a href='mailto:" + mail + "'>" + mail + '</a>')
        $('#modalMap')
            .find('.actions a')
            .attr('href', 'https://www.google.com.mx/maps/dir//' + lat + ',' + lng)
        //<a href="mailto:elcorreoquequieres@correo.com">Aquí el texto que quieras</a>
    })

    $('#searchBrands').on('change keypress keyup', function(e) {
        let search = e.target.value.toUpperCase()

        console.log($('.articules .item'))

        $('.articules .item').each(function(index, value) {
            let name = $(value).data('name')

            let has = name.includes(search)
            if (name.includes(search)) {
                $(value).fadeIn()
                console.log(has, name, search)
            } else {
                $(value).fadeOut()
            }
        })
    })

    $(document).on('click', '#btnResetPassword', function() {
        $('#formLogin').hide()
        $('#formPassword').fadeIn()
    })

    $(document).on('click', '#btnLogin', function() {
        $('#formLogin').fadeIn()
        $('#formPassword').hide()
    })

    function handleDropdownClick(selector, listSelector) {
        const isActive = $(selector).hasClass('active');
        if (isActive) {
            $(selector).removeClass('active');
            $(listSelector).css('display', 'flex');
        } else {
            $(`${selector}.active`).removeClass('active');
            $(selector).addClass('active');
            $(listSelector).css('display', 'none');
        }
    }

    $(document).on('click', '.dropdown-type-product', function() {
        handleDropdownClick('.dropdown-type-product', '.list-type-product');
    });

    $(document).on('click', '.dropdown-category', function() {
        handleDropdownClick('.dropdown-category', '.list-category');
    });

    $(document).on('click', '.dropdown-brand', function() {
        handleDropdownClick('.dropdown-brand', '.list-brand');
    });
    $(document).on('click', '.dropdown-order', function() {
        handleDropdownClick('.dropdown-order', '.list-order');
    });
    $(document).on('click', '.dropdown-show', function() {
        handleDropdownClick('.dropdown-show', '.list-show');
    });
    $(document).on('change', 'input[type="radio"]', function() {
        searchfilter();
    });

    $(document).on('change', 'input[type="checkbox"]', function() {
        if(this.id=="all_products") {
            const isChecked = $(this).prop('checked');
            $('.all_product_id').prop('checked', isChecked);
        }
        if(this.id=="all_categories") {
            const isChecked = $(this).prop('checked');
            $('.all_category_id').prop('checked', isChecked);
        }
        if(this.id=="all_brands") {
            const isChecked = $(this).prop('checked');
            $('.all_brand_id').prop('checked', isChecked);
        }
        searchfilter();
    })

    window.fetchPage = function(url) {
        url = url.replace("http://", "https://");
        console.log("url", url);
        let interval;
        clearInterval(interval)
        interval = setInterval(function() {
        $.ajax({
            type: 'GET',
            url: url,
            dataType: 'json' // Asegúrate de que el servidor responda con JSON
        }).done(function(response) {
            if (response && response.htmlProducts) {
                $('.articules').html(response.htmlProducts);
            }
            if (response && response.htmlPagination) {
                $('.pagination-container').html(response.htmlPagination);
            }else{
                $('.pagination-container').html("<div></div>")
            }
            if (response && response.resultsInfo) {
                $('.results-info-container').html(response.resultsInfo);
            }
            window.scrollTo(0, 0);
        }).fail(function(jqXHR, textStatus, errorThrown) {
            console.error("Error:", textStatus, errorThrown);
            console.error("Response Text:", jqXHR.responseText);
            console.error("Status Code:", jqXHR.status);
        });
        clearInterval(interval)
    }, 1000)
    }

    function searchfilter(){
        let interval;
        clearInterval(interval)
        interval = setInterval(function() {
            // let search = ""
            // const searchParams = new URLSearchParams(location.search);
            //  if(searchParams.get("search")){
            //     search = searchParams.get("search");
            //  }
            //  typeProducts =[];
            // $('.all_product_id:checked').each(function() {
            //     typeProducts.push($(this).attr('id'));
            //  });
            // categories =[];
            // $('.all_category_id:checked').each(function() {
            //         categories.push($(this).attr('id'));
            // });
            // brands =[];
            // $('.all_brand_id:checked').each(function() {
            //     brands.push($(this).attr('id'));
            // });
            // let url = "/search?total=48";

            // if(categories.length>0){
            //     url += `&categorySlug=${categories}`;
            // }
            // if(typeProducts.length>0){
            //     url += `&productTypeSlug=${typeProducts}`;
            // }
            // if(brands.length>0){
            //     url += `&brandSlug=${brands}`;
            // }
            // if(search){
            //     url += `&search=${search}`;
            // }
            const searchParams = new URLSearchParams(location.search);
            const search = searchParams.get("search") || "";
            const typeProducts = $('.all_product_id:checked').map(function() {
                return $(this).attr('id');
            }).get();

            const categories = $('.all_category_id:checked').map(function() {
                return $(this).attr('id');
            }).get();

            const brands = $('.all_brand_id:checked').map(function() {
                return $(this).attr('id');
            }).get();
            const radioOrder = $('input[name="order"]:checked');
            const od = radioOrder.val();

            const radioShow = $('input[name="show"]:checked');
            const am = radioShow.val();

            const queryParams = {
                categorySlug: categories.length > 0 ? categories : undefined,
                productTypeSlug: typeProducts.length > 0 ? typeProducts : undefined,
                brandSlug: brands.length > 0 ? brands : undefined,
                search: search || undefined,
                am,
                od
            };
            //FIX: Con esta seccion se corrige error de que no se renderiza el codigo html.
            const path = window.location.pathname;
            let baseUrl;
            if (path.includes('/category/') || path.includes('/brands/')) {
                baseUrl = path+'?';
            } else if (path.includes('/products')) {
                baseUrl = '/products?';
            } else {
                baseUrl = '/search?';
            }
            // const url = "/search?" + $.param(queryParams);
              const url =  baseUrl + $.param(queryParams);
             $.ajax({
                type: 'GET',
                dataType: 'json',
                url: url
            }).done(function(response) {
                if (response && response.htmlProducts) {
                    $('.articules').html(response.htmlProducts);
                }else{
                    $('.articules').html("<div class='no-results'>No hay resultados para tu busqueda.</div>")
                }
                if (response && response.htmlPagination) {
                    $('.pagination-container').html(response.htmlPagination);
                }else{
                    $('.pagination-container').html("<div></div>")
                }
                if (response && response.resultsInfo) {
                    $('.results-info-container').html(response.resultsInfo);
                }
                })
                .fail(function(jqXHR, textStatus, errorThrown) {
                    console.error("Error:", textStatus, errorThrown);
                    console.error("Response Text:", jqXHR.responseText);
                    console.error("Status Code:", jqXHR.status);
                })
                .always(function() {
                })

            clearInterval(interval)
        }, 1000)
    }

})()
